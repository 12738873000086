/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 102번째 로그에서는 뒤로가기 좀 내버려둬요, etcd가 CNCF 졸업, 연말정산, 10G 네트워크 구축기 등에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "애플 M1 칩"), "\n", React.createElement(_components.h3, null, "CNCF, etcd 프로젝트 졸업"), "\n", React.createElement(_components.h3, null, "낙욧의 아이폰 12 프로맥스 사용기"), "\n", React.createElement(_components.h3, null, "뒤로가기 좀 내버려둬요"), "\n", React.createElement(_components.h3, null, "10G 네트워크 환경 구축 삽질기"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
